
import axios from 'axios';
import React, { useEffect } from 'react';
import ImageUploading from 'react-images-uploading';
import { config } from '../../data/config';
import { getCookies } from "cookies-next";

export default function ImageUploaders({ count = 1, id, component }:any) {
    const [images, setImages] = React.useState([]);
    const [listimage, setlistimage] = React.useState([]);
    const [key, setKey] = React.useState(0);

    const maxNumber = 69;
    let cookie = getCookies();

    const onChange = async (imageList:any, addUpdateIndex:any) => {
        console.log(addUpdateIndex);
        addUpdateIndex.map(async (i:any) => {
            let formData = new FormData();
            formData.append("file", imageList[i].file);
            formData.append("component", component);
            formData.append("parent", id);


            await axios({
                method: "post",
                url: config.url + "/v1/dashboard/filemanager",
                data: formData,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                    "Content-Type": "multipart/form-data"
                },
            })
                .then(function (response) {

                    loaditem();
                    setlistimage([]);
                    console.log(response);
                })
                .catch(function (response) {
                    setlistimage([]);
                    console.log(response);
                });

            setImages(imageList);
        })


        return true;

    };
    const orderup = async (myid:any) => {
        await axios({
            method: "put",
            url: config.url + "/v1/dashboard/filemanager/orderup/" + myid,
            headers: {
                Authorization: 'Bearer ' + cookie['token'],
                "Content-Type": "multipart/form-data"
            },
        })
            .then(function (response) {
                loaditem()

            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    };
    //
    const orderdoown = async (myid:any) => {
        await axios({
            method: "put",
            url: config.url + "/v1/dashboard/filemanager/orderdown/" + myid,
            headers: {
                Authorization: 'Bearer ' + cookie['token'],
                "Content-Type": "multipart/form-data"
            },
        })
            .then(function (response) {
                loaditem()

            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    };
    // orderup
    const loaditem = async () => {
        await axios({
            method: "get",
            url: config.url + "/v1/dashboard/filemanager?id=" + id + "&component=" + component,
            headers: {
                Authorization: 'Bearer ' + cookie['token'],
                "Content-Type": "multipart/form-data"
            },
        })
            .then(function (response) {

                setlistimage(response.data.data)
                var x = key + 1;
                setKey(x);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    }
    const deletedItem = async (myid:any) => {
        await axios({
            method: "delete",
            url: config.url + "/v1/dashboard/filemanager/" + myid,
            headers: {
                Authorization: 'Bearer ' + cookie['token'],
                "Content-Type": "multipart/form-data"
            },
        })
            .then(function (response) {
                loaditem()

            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    };
    useEffect(() => {
        loaditem();
    }, []);
    return (
        <div className="App">
            <div className='grid grid-cols-3 gap-5  '  >
            <div className='col-span-3' >
                <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={count}
                    dataURLKey="data_url"
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                    }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                            <button
                                style={isDragging ? { color: 'red' } : undefined}
                                onClick={onImageUpload}
                                {...dragProps}
                                className='w-[100%] h-[50px] bg-gray-500 text-white p-2 rounded '
                            >
                                Click or Drop here
                            </button>

                        </div>
                    )}
                </ImageUploading>
            </div>
                {listimage.map((item:any) => {
                    return (
                        <div className='shadow border-solid p-4'>
                            <div className='flex gap-2 mt-3'>
                                <button
                                    onClick={(e) => {
                                        deletedItem(item.id);
                                    }}
                                    className='w-[100%] text-center  h-[50px] bg-red-700 text-white p-2 rounded mb-4'
                                >
                                    <img src='/icons/trash.svg' className='m-auto' width={20}></img>
                                </button>
                                <button
                                    onClick={(e) => {
                                        orderup(item.id);
                                    }}
                                    className='w-[100%] h-[50px] bg-green-700 text-white p-2 rounded mb-4'
                                >
                                    <img src='/icons/arrowleft.svg' className='m-auto' width={20}></img>
                                </button>
                                <button
                                    onClick={(e:any) => {
                                        orderdoown(item.id);
                                    }}
                                    className='w-[100%] h-[50px] bg-green-700 text-white p-2 rounded mb-4'
                                >
                                    <img src='/icons/arrowright.svg' className='m-auto' width={20}></img>

                                </button>
                                <a
                                    target='_blank'
                                    href={`/dashboard/Gallery/detailimage/${item.id}`}
                                    className='w-[100%] pt-4 h-[50px] bg-blue-700 text-white p-2 rounded mb-4'
                                >
                                    <img src='/icons/edit.svg' className='m-auto' width={20}></img>

                                </a>
                            </div>

                            <img src={config.url + '/' + item.url} height={100}></img>
                            {item.title != undefined &&
                                <div className='] p-2 mt-2 bg-neutral-700  text-white rounded '>
                                    Title : {item.title}
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
         


        </div>
    );
}